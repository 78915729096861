/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { Box, Flex } from "rebass"
import Wufoo from "react-wufoo-embed"

import {
  Container,
  Layout,
  Section,
} from "@giraldomac/gatsby-theme-mmdbase/src/components/layout"
import { SEO } from "@giraldomac/gatsby-theme-mmdbase/src/components/elements"
import { PubHero } from "../../../components/elements"

const EndService = ({ data }) => {
  const page = data.prismic.end_service

  return (
    <Layout>
      <SEO
        meta_title={page.meta_title}
        meta_description={page.meta_description}
      />
      <PubHero title={RichText.asText(page.title)} />
      <Section>
        <Container>
          <Flex flexWrap="wrap" justifyContent="center">
            <Box
              width={["full", 4 / 5, 3 / 4]}
              sx={{
                backgroundColor: "wrapperbox",
                padding: [4, 5],
              }}
            >
              {RichText.render(page.content)}
              <Wufoo
                header="hide"
                userName="mindmerge"
                formHash="xywyocs0qd1tya"
              />
            </Box>
          </Flex>
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    prismic {
      end_service(lang: "en-us", uid: "end-service") {
        content
        meta_description
        meta_title
        title
      }
    }
  }
`

export default EndService
